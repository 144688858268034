@media only screen and (max-width: 800px) {
  .column-single { 
    display: block;
    min-width:100%;
    max-width:100%;
  }
  
  .text-two-column {
    flex-wrap: wrap;
    flex-direction: column;
    width:100vw;
  }
  .text-three-column {
    flex-wrap: wrap;
    flex-direction: column;
    width:100vw;
  }

  
  
  .content-text {
    width:100%;
  }
  .column-double {
    min-width:100%;
    max-width:100%;
  }  
}


@media only screen and (min-width: 800px) {

.column-single {
  min-width:30%;
  max-width:30%;
}
.column-double {
  min-width:70%;
  max-width:70%;
  
}

}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App {
  justify-content: center;
  text-align: center;
  min-height: 100vh;
  margin-left:5%;
  margin-right:5%;
  margin-top:0px;
  font-size: calc(11px + 1vmin);
  font-family: Arial, Helvetica, sans-serif;
}

.content-home {
  background-image: url('components/front.jpg');
	background-position:center;
	background-repeat:no-repeat;
	background-size:cover;
  width:100%;
  min-height:550px;
  display: flex;
  flex-direction: column;
}

.home-logo {
  text-align:right;
  margin-left:auto;
  width:25%;
  margin-right:2em;
  margin-top: 1em;
}

.home-text {
  color:#A4999F;
  width:50%;
  margin-left:auto;
  text-align: left;
  margin-bottom: 1%;
  margin-right:1em;
}

.home-philosophy {
  width:62%;
  margin-left:auto;
  margin-top: -7%;
  margin-right: 0;
}


.page-content {
  width:100%;
  display: flex;
  flex-direction: column;
}

.page-header-aboutus {
  background-image: url('components/aboutus.jpg');
	background-position:left;
	background-repeat:no-repeat;
	background-size:cover;
  width:100%;
  min-height:300px;
  display: flex;
  flex-basis: 100%;
  flex-direction: row-reverse;
}


.page-header-consultation {
  background-image: url('components/consultation.jpg');
	background-position:left;
	background-repeat:no-repeat;
	background-size:cover;
  width:100%;
  min-height:300px;
  display: flex;
  flex-basis: 100%;
  flex-direction: row-reverse;
}

.page-header-treatments {
  background-image: url('components/consultation.jpg');
	background-position:left;
	background-repeat:no-repeat;
	background-size:cover;
  width:100%;
  min-height:300px;
  display: flex;
  flex-basis: 100%;
  flex-direction: row-reverse;
}

.page-header-contactus {
  background-image: url('components/contactus.jpg');
	background-position:left;
	background-repeat:no-repeat;
	background-size:cover;
  width:100%;
  min-height:300px;
  display: flex;
  flex-basis: 100%;
  flex-direction: row-reverse;
}

.blank {
  width:50%;
}

.headline-image {
  width:40%;
  text-align: right;
  justify-items: right;
  justify-content: right;
  
  align-content: flex-end;
}

.strapline {
  background: #bbc7d1;
  color:white;
  text-align: left;
  padding-left:1em;
  padding-top:1%;
  padding-bottom:1%;
  font-weight: bold;
  font-size3: 90%;
  font-size: calc(0.9vw + 0.5vmin);
}

.strapline-consultation {
  background: #F09BC2;
  color:white;
  text-align: left;
  padding-left:2em;
  padding-top:0.5em;
  padding-bottom:0.5em;
}

.strapline-treatments {
  background: #F09BC2;
  color:white;
  text-align: left;
  padding-left:2em;
  padding-top:1%;
  padding-bottom:1%;
  font-weight: bold;
  font-size: 90%;
}

.strapline p{
  margin: 0;
  padding: 0;
  line-height: 1;
}

.green {
  color: #5DB5A1;
  font-weight: bold;
}
.page-title {
  color: #F2ABCB;
  font-weight: bold;
  text-align: left;
  margin-top:1em;
  padding-left:2em;
}

.page-text {
  margin-top:0.5em;
  text-align:left;
  padding-left:2em;
  padding-right:2em;
}

.name {
  font-weight: bold;
}

.content-two-column {
  display:flex;
  flex-direction: row;
  flex-basis: 100%;
}

.column-two {
  flex-basis:40%;
  width:40%;
}


.content-header-img {
  max-width:100%;
  }

.content-header {
    width:100%;
    height:300px;
    display: flex;
    flex-basis: 100%;
    flex-direction: row-reverse;  
}

.icon-lotos {
  max-width:70px;
  padding-bottom:0;
  padding-top:0px;
}

.icon-lotos-2 {

  padding-left:1em;
  max-width:70px;
  margin-bottom:0;
  padding-bottom:0;
}

.title-bottom {
  padding-top: 1em;
  padding-left: 10px;
}

.content-section-title {
  padding-top: 1em;
  padding-left:1em;
  display:flex;
  align-items: center;
 }

 .content-section-title-2 {
  padding-top: 1em;
  padding-bottom: 1em;

  display:flex;
  align-items: center;
 }

.section-title {
  padding-left:1em;
  2margin-top: -10px;
  padding-top: 10px;
  vertical-align: bottom;
  2padding-bottom: 0.5em;
}


.section-title-2 {
  padding-left:1em;
  margin-top: -10px;
  padding-top: 0;
  padding-bottom: 0.5em;
}
.section-title-two {
  margin-top: -10px;
  padding-top: 0;
  padding-bottom: 0.5em;
}

.pink {
  background: #F2ABCB;
}

.red {
  color: #F2ABCB;
  font-weight: bold;
}

.bottom-green {
  border-bottom: 1px solid #5DB5A1;
}

.bottom-pink {
  border-bottom: 1px solid #F2ABCB;
}

.bottom-brown {
  border-bottom: 1px solid #9F828C;
}

.brown {
  color: #9F828C;
  font-weight: bold;
}

.content-text {
  2padding-left: 1em;
  color: #707070;
 	background-position:center;
	background-repeat:no-repeat;
  background-size:cover;
  text-align:left;
}

.content-title {
    color: #F2ABCB;
    font-weight: bold;
    text-align: left;
    margin-top:1em;
}

.text-two-column {
  margin-top:1em;
  display: flex;
  text-align:left;
  align-content: space-between;
  width:100%;
  padding-left:1em;
}



.text-one-column {
  margin-top:1em;
  display2: flex;
  text-align:left;
  align-content: space-between;
  width:100%;
  padding-left:1em;
  2align-items: stretch;
}


.with-padding-right {
  width:60%;
}

.with-space {
  margin-left:3%;
}

.with-space-right {
  margin-right:3%;
  22text-align: right;
}

.with-space-left {
  margin-right:3%;
  padding-left:1em;
  text-align: right;
}

.column-single-right {
  min-width:35%;
  max-width:35%;
  margin-right:3%;
}

.column-single-middle {
  min-width:34%;
  max-width:34%;
}

.column-double-left {
  min-width:62%;
  max-width:62%;
  margin-right: 3%;
}


.text-three-column {

  padding-left:1em;
  margin-top:1em;
  display: flex;
  text-align:left;
  align-content: space-between;
  
  width:100%;
  min-width:100%;
  flex-basis:100%;
  2align-items: center;
  2justify-content: center;
  
}

.column-single-last {
  min-width:33%;
  max-width:33%;
}

.2column-double {
  min-width:70%;
  max-width:70%;
  
}

.imgimg {
  max-width:100%;
  padding-bottom:1em;
}

.column-img {
  width:100%;
}

.column-right {
  text-align: right;
  align-items: top;
  flex-grow: 1;
  width:100%;
}

.table-wrapper {
  display: table;
}
.table-cell {
  display: table-cell;
  vertical-align: top;
}

.contactus-lotos {
  2margin-bottom:-150px;
  2width:100%;
  opacity: 0.2;
  margin-right:3em;;
}

.lotos-pink {
  filter: invert(98%) sepia(76%) saturate(3101%) hue-rotate(282deg) brightness(103%) contrast(90%);
}

.lotos-green {
  filter: invert(74%) sepia(9%) saturate(1801%) hue-rotate(116deg) brightness(86%) contrast(86%)
}

.lotos-brown {
  filter: invert(55%) sepia(19%) saturate(318%) hue-rotate(288deg) brightness(95%) contrast(89%);
}


.content-header-img-3 {
  max-width:100%;
  height: auto;
  }

.content-header-3 {
    width:100%;
    2display: flex;
    2flex-basis: 100%;
    2flex-direction: row-reverse;  
}

.content-3 {
  background-image: url('components/footer5.png');
	background-position:bottom;
	background-repeat:no-repeat;
  background-size:contain;
  padding-bottom: 100px;
  width:100%;
  2font-size:90%;

}


.content-4 {
  background-image: url('components/footer5.png');
	background-position:bottom;
	background-repeat:no-repeat;
  background-size:contain;
  padding-bottom: 9em;
  width:100%;
  2font-size:90%;
}

.footer {
  2border-top: 1px solid #909090;
  text-align: left;
  margin-top: 2%;
  color: #909090;
  font-size:0.8em;
}

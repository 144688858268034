#navbar {
    border2: 1px solid hsl(0, 0%, 44%);
    2border-radius:15px;
    margin-left:0;
    padding-left:0;
    padding-right:0;
    margin-right:0;
    width:100%;
    vertical-align:bottom;
    2margin-bottom:0;
    2padding-bottom:0;
}


#navbar a{
    color:#707070;
}

.logo {
    height: calc(10vmin);
}

.callus {
    color: #F2ABCB;
    margin-top:auto;
    margin-bottom:8px;
}

.filler {
    flex-grow: 1;

}
#basic-navbar-nav {
    margin-top:auto;
    margin-bottom:8px;
}

#333basic-navbar-nav a:hover {
    color: #F2ABCB;
    text-decoration: none;
}
